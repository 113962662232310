@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap");

html,
body,
#root {
  height: 100%;
  margin: 0; /* Reset default margin */
  padding: 0; /* Reset default padding */
  background-color: #6a706d;
  color: white;
}

.first {
  background-image: url("bg.jpg");
}

.App {
  display: flex;
  flex-direction: column;
  font-family: "Raleway", sans-serif;
  min-height: 100vh;
}

.App-header {
  position: absolute;
  top: 0;
  width: 100%;
}

.inner-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-left: 25px;
  padding-right: 25px;
}

.App-link {
  color: #61dafb;
}

.App-body {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.first {
  flex-direction: column;
  justify-content: center;
}

.second {
  background-color: #6a706d;
}

.Main-text {
  position: absolute;
  font-size: calc(34px + 2vmin);
  color: white;
  letter-spacing: 5px;
  width: 40%;
  text-align: center;
  margin: 0;
}

.bottles {
  margin-bottom: -150px;
}

.smaller {
  scale: 0.85;
}

.clean{
	text-decoration: none;
	color: inherit;
}

.clickable{
	cursor: pointer;
}

.Index-page-scroll-indicator {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 3;
  padding: 0 10px;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  line-height: 0;
  /*-webkit-transform: translatex(-50%);
  -moz-transform: translatex(-50%);
  -ms-transform: translatex(-50%);
  -o-transform: translatex(-50%);
  transform: translatex(-50%);*/
  transition: transform ease 0.5s;
}

.Index-page-scroll-indicator-line {
  height: 45px;
  width: 2px;
  background-color: #fff;
  transition: transform 170ms ease-out, opacity 170ms ease-out;
}

.hidden .Index-page-scroll-indicator-line {
  opacity: 0;
  pointer-events: none;
  transform: translatey(20px);
}

.Index-page-scroll-indicator:hover {
  transform: translatey(0.5vh);
}

.second,
.contact {
  flex-direction: column;
  gap: 30px;
}

.Inner-second {
  display: grid;
  grid-template-columns: 1fr 2fr; /* Adjust the column widths as needed */
  gap: 20px; /* Gap between the two sections */

  width: 100%;
}

/* Style for the product grid */
.product-grid {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  ); /* Responsive grid */
  gap: 15px;
  padding: 15px;
  background-color: #b5b0a9;
}

/* Style for individual product */
.product {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
  color: black;
}

.product:hover {
  border: 1px solid #3e423d; /* Change border color on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow on hover */
}

.product.selected {
  border: 2px solid #454c4c; /* Change border for selected product */
  box-shadow: 0 4px 8px rgba(69, 76, 76, 0.2); /* Add shadow for selected product */
}

.product-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust the height as needed */
}

.product-image img {
  max-width: 100%;
  max-height: 100%;
}

.product-details {
  text-align: center;
}

/* Style for the product information section */
.product-info {
  height: 100%;
  background-color: #b5b0a9;
  padding: 15px;
  width: 85%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-title {
  letter-spacing: 20px;
  text-align: center;
}

.inner-product-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-description {
  line-height: 30px;
  font-size: 18px;
}

.contact {
	display: flex;
	width: 70%;
	height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #b5b0a9;
}

.inner-contact {
  display: flex;
  width: 100%;
  gap: 25px;
}

.input-contact {
  width: 70%;
  padding: 15px;
}

.input-contact > form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input-contact input {
  height: 32px;
  width: 100%;
}

.input-contact textarea {
  height: 120px;
  font-size: 17px;
  resize: none;
}

.info-contact {
  width: 35%;
  background-color: #6a706d;
  padding: 15px;
}

.sendButton {
  color: #fff;
}

.sendButton > a {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  background-color: #000;
  padding: 9px 40px 14px;
  font-style: italic;
  transition: all 0.3s ease;
}

.sendButton > a:hover {
  letter-spacing: 2px;
}

.sendButton span {
  text-decoration: none;
}





.hamburger-icon {
  position: relative;
  z-index: 10;
}

.hamburger-icon span {
  height: 4px;
  width: 30px;
  border-radius: 25px;
  background-color: white;
  display: block;
  margin: 5px 0px 5px 0px;
  transition: 0.7s ease-in-out;
  transform: none;
}

#openmenu:checked ~ .menu-pane {
  left: -5vw;
  transform: translateX(-5vw);
}

#openmenu:checked ~ .body-text {
display: none;
}

#openmenu:checked ~ .hamburger-icon span:nth-of-type(2) {
  transform: translate(0%, 175%) rotate(-45deg);
  background-color: white;
}

#openmenu:checked ~ .hamburger-icon span:nth-of-type(3) {
  transform: rotate(45deg);
  background-color: white;
}

#openmenu:checked ~ .hamburger-icon span:nth-of-type(1) {
  opacity: 0;
}

#openmenu:checked ~ .hamburger-icon span:nth-of-type(4) {
  opacity: 0;
}

div.menu-pane {
  position: absolute;
  transform: translateX(-105vw);
  transform-origin: (0, 0);
  width: 100vw;
  height: 100%;
  transition: 0.6s ease-in-out;
  z-index: 9;
}

.menu-pane p {
  color: black;
  font-size: 0.6em;
}

.menu-pane nav {
  padding: 10%;
  background-color: rgba(57, 66, 63, .7);
}

.menu-links li, a, span {
      transition: 0.5s ease-in-out;
}

.menu-pane ul {
  padding: 10%;
  display: inline-block;
}

.menu-pane li {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 10px;
    font-size: 1em;
}


.menu-pane li:first-child {
  font-size: 1.3em;
  margin-left: -10px;
}


.menu-links li a {
  color: white;
  text-decoration: none;
}


.menu-links li:hover a {
  color: #FFAB91;
}

.menu-links li:first-child:hover a {
  color: black;  
  background-color: #FFAB91;
}

#QC-info {
  background-color: #FFAB91;
    border: 2px solid;
  border-color: #FFAB91;
display: block;
  opacity: 0;
  
}

.menu-links li:first-child:hover #QC-info {
opacity: 1;
}

.menu-links li:first-child:hover #DC-info {
opacity: 1;
}

#DC-info {
  background-color: #FFAB91;
    border: 2px solid;
  border-color: #FFAB91;
display: block;
  opacity: 0;
}


.menu-links li:first-child a {
  padding: 5px;
}

input.hamburger-checkbox {
  position: absolute;
  z-index: 11;
  width: 30px;
  opacity: 0;
  height: 30px;
  cursor: pointer;
}
