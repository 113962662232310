.contact-outer{
	display: flex;
    flex-direction: column;
    background-color: #b5b0a9;
}

.sendButtonDisabled {
  color: #fff;
  display: inline-block;
  background-color: #8c8c8c;
  padding: 9px 40px 14px;
  font-style: italic;
  width: fit-content !important;
}

.sendButton {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  background-color: #000;
  padding: 9px 40px 14px;
  font-style: italic;
  transition: all 0.3s ease;
  width: fit-content !important;
}

.sendButton:hover {
  letter-spacing: 2px;
}

.emailInput {
  margin: 25px 0;
}

.feedbackPlaceHolder {
  margin: 12px 0;
  font-size: 16px;
}

.successText {
  margin: 12px 0;
  font-size: 16px;
  color: #84e384;
  font-weight: bold;
  text-align: center;
}

.errorText {
  margin: 12px 0;
  font-size: 16px;
  color: red;
  font-weight: bold;
  animation: shake 0.3s ease-in-out;
  text-align: center;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(5px);
  }
  40% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(5px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}


.footer{
	display: flex;
	height: 40px;
	width: 100%;
	justify-content: center;
	align-items: center;
	background-color: #6a706d;
}

.cr{
	font-size: larger;
}